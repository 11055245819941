import * as React from "react"
import styled from "styled-components"

const PageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const IndexPage = () => (
  <PageContainer>
    <h1>Verdn Documentation</h1>
    <p>Request early access by emailing us at developers@verdn.com</p>
  </PageContainer>
)

export default IndexPage

import * as React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import SyntaxHighlighter from "react-syntax-highlighter"
import { stackoverflowLight } from "react-syntax-highlighter/dist/esm/styles/hljs"
import { useInView } from "react-intersection-observer"
import { transformContent } from "../utils/api-content"

const HEX_GET = "#1a884c"
const HEX_POST = "#00aab8"
const HEX_PATCH = "#ff8945"
const HEX_DELETE = "#882000"
const HEX_SPEC = "#324B4C"

const removeTrailingSlash = input =>
  input
    ? input
        .replace(/^http:\/\//, "") // remove the leading http:// (temporarily)
        .replace(/\/+/g, "/") // replace consecutive slashes with a single slash
        .replace(/\/+$/, "") // remove trailing slashes
    : ""

const PageContainer = styled.div`
  display: flex;
  width: 100vw;
  min-height: 100vh;
  background: white;
  position: relative;

  .method.GET {
    background-color: ${HEX_GET};
  }

  .method.POST {
    background-color: ${HEX_POST};
  }

  .method.PATCH {
    background-color: ${HEX_PATCH};
  }

  .method.DELETE {
    background-color: ${HEX_DELETE};
  }

  .method.SPEC {
    background-color: ${HEX_SPEC};
  }

  /* @media (prefers-color-scheme: dark) {
    * {
      background: black !important;
      color: white;
    }
  } */
`

const LogoContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 1rem;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  border-bottom: 1px solid #ddd;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);

  img {
    height: 1rem;
    margin: 0;
  }

  span {
    font-family: "Space Grotesk", sans-serif;
    font-size: 1.2rem;
    margin-top: 0.2rem;
    margin-left: 0.4rem;
    font-weight: 600;
  }
`

const NavSidebar = styled.div`
  /* padding: 1rem 1rem; */
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: #fafaf5;
  /* border-right: 2px solid #95b0b1; */
  min-width: 220px;
  width: 20vw;
  max-width: 260px;
`

const NavLinks = styled.nav`
  /* padding: 1rem; */
  position: relative;
  width: 100%;
  height: 100%;
  /* display: flex;
  flex-direction: column; */
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 8rem;

  font-size: 1rem;

  &* {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
    color: black;
    width: 100%;
    display: block;
    padding: 0.4rem 1.4rem;
    margin-left: 0.2rem;
  }

  ul {
    margin: 0;
    list-style-type: none;
  }

  .section-subtitle:first-child {
    margin-top: 0;
  }

  ul li span {
    margin: 0;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .section-title-container {
    margin-top: 2rem;
  }

  .section-title-container > ul {
    margin-top: 0.6rem;
  }

  .section-title {
    font-family: "Space Grotesk", sans-serif;
    font-size: 1.2rem;
    color: #005a5f;
    /* text-transform: uppercase; */
    font-weight: 700;
    display: block;
    margin-bottom: 0.2rem;
    padding: 0rem 1.4rem;
    margin-left: 0.2rem;
  }

  .section-subtitle {
    font-weight: 500;
  }

  .section-detail-title-container li {
    font-weight: 400;
    /* margin-left: 0.4rem; */
    width: 100%;
  }

  .section-subtitle-container li {
    /* margin-bottom: 0.8rem; */
    margin-bottom: 0.2rem;
    width: 100%;
  }

  .section-subtitle {
    /* margin-bottom: 0.4rem; */
  }

  .section-subtitle-container li ul li {
    /* margin-bottom: 0.4rem; */
    /* margin-left: 0.4rem; */
    width: 100%;
    margin-bottom: 0;
  }

  .section-detail-title {
    padding: 0.2rem 1.4rem;
    margin-left: 1rem;
  }

  .active {
    background: #dce8e4;
    border-radius: 0.2rem;
    font-weight: 600;
    color: #238d80;
  }
`

const ContentHousing = styled.div`
  position: relative;
  z-index: 20;
  margin-left: 220px;
  width: calc(100vw - 220px);
  height: 100%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid #eee; */
  border-left: 1px solid #aaa;
`

const ContentInner = styled.div`
  width: 100%;
  padding-bottom: 20rem;
`

const ContentSection = styled.div`
  padding: 4rem 6rem 4rem;
  border-top: ${({ innerBorder }) => innerBorder !== true && `1px solid #ccc`};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > div {
    width: 100%;
    max-width: 1100px;
    border-top: ${({ innerBorder }) =>
      innerBorder === true && `1px solid #ccc`};
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .halves {
    width: 100%;
    display: flex;
    align-items: flex-start;
  }

  .left,
  .right {
    flex: 1;
    width: 50%;
    /* display: flex;
    flex-direction: column;
    align-items: flex-start; */
    display: block;
  }

  .right {
    /* margin: 1rem 0; */
    display: block;
    overflow-y: scroll;
    padding: 2rem 0 0;
    margin-left: 4rem;
    position: sticky;
    top: 0;
    max-height: calc(100vh - 8rem);
    align-items: stretch;
    /* justify-content: flex-start; */
  }

  .right h5 {
    margin-bottom: 0.6rem;
  }

  .left.object {
    h5 {
      font-size: 1.2rem;
    }

    & > div > ul {
      list-style-type: none;
      margin-left: 0;
    }
    li h3 {
      margin-top: 0;
      margin-bottom: 0.4rem;
      font-size: 1rem;
    }
    li {
      border-top: 1px dashed #ddd;
      padding: 1rem 0;
      margin-bottom: 0;
    }
    .field-type {
      margin-left: 0.4rem;
      color: #888;
    }
    .field-type.required {
      background-color: #f1f1e7;
      color: #ff8946;
      padding: 0 0.2rem;
      border-radius: 0.2rem;
    }
    .field-type.optional {
      color: #7ac1c5;
    }
    ul li ul {
      list-style-type: "↳ ";
    }
  }

  h1 {
    border-left: 4px solid #005a5e;
    /* border-bottom: 1px dashed #005a5e; */
    background: #d6eae3;
    padding: 0.4rem 1.2rem;
    border-radius: 0.2rem;
    /* padding-left: 1rem; */
    display: inline-block;
    margin-bottom: 2rem;
  }

  h2 {
    background: #d6eae3;
    padding: 0.2rem 0 0.2rem 0.6rem;
    border-radius: 0.2rem;
    display: inline-block;
    margin-bottom: 2rem;
  }

  h2 .method-decoration {
    padding: 0rem 0.4rem;
    margin-left: 1rem;
    background: #005a5e;
    color: white;
    display: inline-block;
  }
`

const CodeBlockHousing = styled.div`
  border-radius: 0.6rem;
  overflow-y: scroll;
  overflow-x: scroll;
  margin-bottom: 1.6rem;
  /* padding: 1rem; */
`

const CodeBlock = ({
  title,
  language = "JSON",
  heightLimit = "30vh",
  content,
}) => {
  return (
    <>
      <h5>{title}</h5>
      <CodeBlockHousing style={{ maxHeight: heightLimit }}>
        <SyntaxHighlighter
          style={stackoverflowLight}
          language={language}
          customStyle={{
            padding: "1rem",
            margin: 0,
          }}
        >
          {content || ""}
        </SyntaxHighlighter>
      </CodeBlockHousing>
    </>
  )
}

const ContentBlock = ({
  id,
  title,
  decoration,
  inner,
  endpoint,
  isObject,
  leftContentRaw,
  rightContentRaw,
  endpoints,
  apiPath,
  codeBlock,
  onIntersect,
}) => {
  const {
    ref,
    inView,
    // entry
  } = useInView({
    rootMargin: "-30% 0px -70% 0px",
    threshold: 0,
  })
  React.useEffect(() => {
    if (inView && onIntersect) {
      onIntersect(id)
    }
  }, [inView, id, onIntersect])

  return (
    <ContentSection id={id} ref={ref} innerBorder={!!inner}>
      <div>
        <div className="halves">
          <div className={`left${isObject ? " object" : ""}`}>
            {inner ? (
              <h2>
                {title}{" "}
                {decoration && (
                  <span className={`method-decoration method ${decoration}`}>
                    {decoration}
                  </span>
                )}
              </h2>
            ) : (
              <h1>{title}</h1>
            )}
            {leftContentRaw && (
              <div dangerouslySetInnerHTML={{ __html: leftContentRaw }} />
            )}
          </div>
          <div className="right">
            {/* <div
              style={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            > */}
            {/* <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            > */}
            {endpoint && (
              <EndpointExample
                method={endpoint.method}
                path={`/v1/${apiPath}/${endpoint.apiPath}`}
                body={endpoint.request || ""}
              />
            )}
            {codeBlock && (
              <CodeBlock
                title={codeBlock.title}
                language={codeBlock.language}
                content={codeBlock.content}
                heightLimit={
                  endpoint &&
                  (endpoint.method === "POST" || endpoint.method === "PATCH")
                    ? "40vh"
                    : "70vh"
                }
              />
            )}
            {endpoints && (
              <EndpointList
                basePath={apiPath}
                paths={endpoints.map(({ method, apiPath: subPath }) => ({
                  method,
                  subPath,
                }))}
              />
            )}
            {rightContentRaw && (
              <div dangerouslySetInnerHTML={{ __html: rightContentRaw }} />
            )}
          </div>
          {/* </div> */}
        </div>
      </div>
    </ContentSection>
  )
}

const SideTable = styled.div`
  border-radius: 0.2rem;
  border: 1px solid #ccc;
  overflow: hidden;

  .heading {
    background: ${({ dark }) => (dark ? "#111" : "#eee")};
    color: ${({ dark }) => (dark ? "#fff" : "#000")};
    padding: 1rem;
    font-size: 0.9rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  .content {
    background: #f4f4f4;
    padding: 1rem 0;
  }

  .endpoint {
    font-family: "Space Mono";
    display: flex;
    padding: 0.3rem 0;
  }

  .endpoint .method {
    width: 6rem;
    color: white;
    font-weight: 600;
    margin-right: 0.4rem;
    text-align: right;
    padding-right: 0.6rem;
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
  }
`

const CodeTable = styled.div`
  flex: 1;
  font-family: "Space Mono";
  border-radius: 0.2rem;
  /* border: 1px solid #95b0b2; */
  overflow: hidden;
  margin-bottom: 1rem;

  .heading {
    background: #324b4c;
    color: white;
    padding: 1rem;
    font-size: 0.9rem;
  }

  .method {
    text-transform: uppercase;
    font-weight: 600;
  }

  .path {
    margin-left: 1rem;
  }

  .headers {
    color: white;
    background: #444;
    padding: 1rem;
  }
  .headers ul {
    margin-top: 0.4rem;
  }
  .headers li {
    font-size: 0.8rem;
    margin: 0;
  }

  .slim-header {
    width: 100%;
    background: #617c7d;
    color: white;
    padding: 0.2rem 1rem;
    font-size: 0.9rem;
    font-weight: 600;
    font-family: Inter;
  }

  table {
    background: #eee;
    width: 100%;
    font-size: 0.8rem;
    margin: 0;
  }

  td {
    /* border: 1px solid black; */
    padding: 0.4rem 1rem;
  }

  td:first-of-type {
    font-weight: 600;
  }

  .content {
    background: #f4f4f4;
    padding: 1rem 0;
  }

  .endpoint {
    font-family: "Space Mono";
    display: flex;
    padding: 0.3rem 0;
  }

  .endpoint span:first-child {
    width: 6rem;
    /* background: red; */
    color: #011627;
    font-weight: 600;
    margin-right: 0.4rem;
    text-align: right;
  }
`

const EndpointList = ({ basePath, paths }) => (
  <SideTable>
    <div className="heading">Endpoints</div>
    <div className="content">
      {paths.map(({ method, subPath }) => (
        <div className="endpoint">
          <span className={`method ${method}`}>{method}</span>
          <span>{removeTrailingSlash(`/v1/${basePath}/${subPath}`)}</span>
        </div>
      ))}
    </div>
  </SideTable>
)

const EndpointExample = ({ method, path, body }) => {
  const jsonRequestBody = method === "POST" || method === "PUT"
  return (
    <>
      <CodeTable>
        <div className="heading">
          <span className="method">{method}</span>
          <span className="path">{removeTrailingSlash(path)}</span>
        </div>
      </CodeTable>
      {jsonRequestBody ? (
        <CodeBlock title="Example request body (JSON)" content={body} />
      ) : null}
    </>

    //   <div className="slim-header">
    //     <span>Headers</span>
    //   </div>
    //   <table>
    //     <tr>
    //       <td>Authorization</td>
    //       <td>Bearer vk_test_3e6e82654bba41aa90b5ba02bb22ef11</td>
    //     </tr>
    //     {jsonRequestBody && (
    //       <tr>
    //         <td>Content-Type</td>
    //         <td>application/json</td>
    //       </tr>
    //     )}
    //   </table>
    //   {jsonRequestBody ? (
    //     <>
    //       <div className="slim-header">
    //         <span>Body (JSON)</span>
    //       </div>
    //       <SyntaxHighlighter
    //         style={stackoverflowLight}
    //         customStyle={{
    //           overflowY: "scroll",
    //           borderRadius: "0.6rem",
    //           padding: "1rem",
    //           // maxWidth: 100,
    //         }}
    //         wrapLongLines
    //         language="json"
    //       >
    //         {body}
    //       </SyntaxHighlighter>
    //     </>
    //   ) : null}
    // </CodeTable>
  )
}

const Notice = styled.div`
  position: fixed;
  height: 30px;
  width: calc(100vw - 220px);
  right: 0;
  bottom: 0;
  background: #301f00;
  color: white;
  font-size: 1.2rem;
  z-index: 40000;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.4;
`

const ApiPage = ({ data }) => {
  // console.time("contentParsing");
  const content = transformContent(data)
  // console.timeEnd("contentParsing");
  const [activeSlug, setActiveSlug] = React.useState()
  const navLinksRef = React.useRef()

  const onIntersect = slug => {
    setActiveSlug(slug)
    if (navLinksRef.current) {
      // Not searching by class ".active" due to render delay
      const activeLink = document.querySelector(`[href="/api/#${slug}"]`)
      if (activeLink) {
        console.log("GOT ACTIVE LINK", activeLink.offsetTop)
        navLinksRef.current.scrollTop = Math.min(activeLink.offsetTop - 80, 80)
      }
    }
  }
  console.log("rerender")

  const getActiveClass = (className, active) =>
    `${className}${active ? " active" : ""}`

  return (
    <PageContainer style={{ position: "relative" }}>
      <Notice>
        The Verdn API is in beta, and this documentation is subject to change.
      </Notice>
      <NavSidebar>
        {/* {activeSlug} */}
        <LogoContainer>
          <img src="/logo-borderless-color.png" />
          <span>API</span>
        </LogoContainer>
        <NavLinks id="nav_links" ref={navLinksRef}>
          <ul>
            {content.map(group => (
              <li className="section-title-container">
                <span className="section-title">{group.title}</span>
                <ul className="section-subtitle-container">
                  {group.sections.map(section => {
                    // const sectionActive = activeSlug === section.slug;
                    // const subSectionActive = activeSlug === subSection.slug;
                    // const objectActive =
                    const lol = "lol"
                    return (
                      <li>
                        <Link
                          to={`#${section.slug}`}
                          className={getActiveClass(
                            "section-subtitle",
                            activeSlug === section.slug
                          )}
                          // className="section-subtitle"
                          // data-observe-id={section.slug}
                        >
                          {section.title}
                        </Link>
                        {activeSlug?.includes(section.slug) &&
                          section.sections && (
                            <ul className="section-detail-title-container">
                              {section.sections.map(subSection => (
                                <li>
                                  <Link
                                    to={`#${subSection.slug}`}
                                    className={getActiveClass(
                                      "section-detail-title",
                                      activeSlug === subSection.slug
                                    )}
                                    // className="section-detail-title"
                                    // data-observe-id={subSection.slug}
                                  >
                                    {subSection.title}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          )}
                        {activeSlug?.includes(section.slug) &&
                          section.object && (
                            <ul>
                              <li>
                                <Link
                                  to={`#${section.object.slug}`}
                                  className={getActiveClass(
                                    "section-detail-title",
                                    activeSlug === section.object.slug
                                  )}
                                  // className="section-detail-title"
                                  // data-observe-id={section.object.slug}
                                >
                                  {section.object.title}
                                </Link>
                              </li>
                              {section.endpoints &&
                                section.endpoints.map(endpoint => (
                                  <li>
                                    <Link
                                      to={`#${endpoint.slug}`}
                                      className={getActiveClass(
                                        "section-detail-title",
                                        activeSlug === endpoint.slug
                                      )}
                                      // className="section-detail-title"
                                      // data-observe-id={endpoint.slug}
                                    >
                                      {endpoint.title}
                                    </Link>
                                  </li>
                                ))}
                            </ul>
                          )}
                      </li>
                    )
                  })}
                </ul>
              </li>
            ))}
          </ul>
        </NavLinks>
      </NavSidebar>
      <ContentHousing>
        <ContentInner>
          {content.map(group =>
            group.sections.map(section => (
              <>
                <ContentBlock
                  onIntersect={onIntersect}
                  id={section.slug}
                  title={section.title}
                  leftContentRaw={section.content}
                  apiPath={section.apiPath}
                  endpoints={section.endpoints}
                  rightContentRaw={section.aside}
                />
                {section.sections &&
                  section.sections.map(innerSection => (
                    <ContentBlock
                      onIntersect={onIntersect}
                      id={innerSection.slug}
                      inner
                      title={innerSection.title}
                      leftContentRaw={innerSection.content}
                      rightContentRaw={innerSection.aside}
                    />
                  ))}
                {section.object && (
                  <ContentBlock
                    onIntersect={onIntersect}
                    id={section.object.slug}
                    inner
                    isObject
                    decoration="SPEC"
                    title={section.object.title}
                    leftContentRaw={section.object.content}
                    codeBlock={{
                      language: "json",
                      title: "Example object",
                      content: section.object.example,
                    }}
                  />
                )}
                {section.endpoints &&
                  section.endpoints.map(endpoint => (
                    <ContentBlock
                      onIntersect={onIntersect}
                      id={endpoint.slug}
                      inner
                      apiPath={section.apiPath}
                      endpoint={endpoint}
                      title={endpoint.title}
                      decoration={endpoint.method}
                      isObject
                      leftContentRaw={endpoint.content}
                      codeBlock={{
                        language: "json",
                        title: "Example response (JSON)",
                        content: endpoint.response,
                      }}
                    />
                  ))}
              </>
            ))
          )}
        </ContentInner>
      </ContentHousing>
    </PageContainer>
  )
}

/**
 * 
 *                                       <EndpointExample
                                        method={innerSubSection.example.method}
                                        path={`/v1/${subSection.basePath}/${innerSubSection.example.path}`}
                                        body={innerSubSection.example.body}
                                      />

 */

// TODO: Alphabetical / numerical sorting across all nestings!

export default ApiPage

export const pageQuery = graphql`
  query ApiPageQuery {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "//api//" } }) {
      edges {
        node {
          fileAbsolutePath
          html
          frontmatter {
            title
            apiPath
            method
          }
        }
      }
    }
    allFile(
      filter: { absolutePath: { regex: "//api//" }, ext: { eq: ".json" } }
    ) {
      edges {
        node {
          absolutePath
          internal {
            content
          }
        }
      }
    }
  }
`

const capitalizeFirstLetter = string =>
  string.charAt(0).toUpperCase() + string.slice(1)

const slugify = input => input.toLowerCase().split(" ").join("-")

const formatTitle = filename => {
  const withoutNumber = filename.includes("_")
    ? filename.split("_")[1]
    : filename
  return capitalizeFirstLetter(withoutNumber.split("-").join(" "))
}

const randomDocId = () => {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  let autoId = ""
  while (autoId.length < 20) {
    const char = chars[Math.floor(Math.random() * chars.length)]
    autoId += char
  }
  return autoId
}

const validateAndGetMap = (map, parts, targetIndex, currentIndex = 0) => {
  if (!map[parts[currentIndex]]) {
    map[parts[currentIndex]] = {}
  }
  if (currentIndex === targetIndex) return map[parts[currentIndex]]
  return validateAndGetMap(
    map[parts[currentIndex]],
    parts,
    targetIndex,
    currentIndex + 1
  )
}

const extractContent = nodes => {
  const contentMap = {}
  nodes.forEach(
    ({
      // filePath,
      filePathParts,
      fileName,
      // fileExtension,
      html,
      content,
      title,
      apiPath,
      method,
      nodeType,
    }) => {
      const mapPart = validateAndGetMap(
        contentMap,
        filePathParts,
        filePathParts.length - 1
      )
      const parentKey = filePathParts[filePathParts.length - 1]
      const grandparentKey = filePathParts[filePathParts.length - 2]

      if (nodeType === "md") {
        mapPart[fileName] = html
        if (title) {
          mapPart.title = title
        } else {
          mapPart.title = formatTitle(parentKey)
        }
      }
      if (nodeType === "json") {
        mapPart[fileName] = content
      }

      if (parentKey === "object" || grandparentKey === "endpoints") {
        mapPart[fileName] = mapPart[fileName].replace(
          /::required::/gi,
          () => `<span class="field-type required">required</span>`
        )
        mapPart[fileName] = mapPart[fileName].replace(
          /::optional::/gi,
          () => `<span class="field-type optional">optional</span>`
        )
        mapPart[fileName] = mapPart[fileName].replace(
          /::(.*?)::/gi,
          str => `<span class="field-type">${str.slice(2, -2)}</span>`
        )
      }

      if (apiPath) {
        mapPart.apiPath = apiPath
      }

      if (method) {
        mapPart.method = method
      }
    }
  )
  // console.log("Content map:", JSON.stringify(contentMap, null, 2));
  return contentMap
}

const extractPathMeta = absolutePath => {
  const [, filePath] = absolutePath.split("/api/")
  const filePathParts = filePath.split("/")
  const fileNameRaw = filePathParts.splice(-1)
  const [fileName, fileExtension] = fileNameRaw[0].split(".")

  return { filePath, filePathParts, fileName, fileExtension }
}

const transformContent = data => {
  const markdownNodes = data.allMarkdownRemark.edges.map(
    ({
      node: {
        fileAbsolutePath,
        html,
        frontmatter: { title, apiPath, method },
      },
    }) => {
      const { filePath, filePathParts, fileName, fileExtension } =
        extractPathMeta(fileAbsolutePath)

      return {
        filePath,
        filePathParts,
        fileName,
        fileExtension,
        html,
        title,
        apiPath,
        method,
        nodeType: "md",
      }
    }
  )
  const jsonNodes = data.allFile.edges.map(
    ({
      node: {
        absolutePath,
        internal: { content },
      },
    }) => {
      const { filePath, filePathParts, fileName, fileExtension } =
        extractPathMeta(absolutePath)

      if (content && content.includes("hasMore")) {
        // This hack is disgusting... Sorry...
        content = content.replace(
          `    }
  ],
  "hasMore": false,`,
          `    },
    {...},
    {...}
  ],
  "hasMore": false,`
        )
      }

      content = content.replace(
        /"FIRESTORE_TIMESTAMP"/g,
        Math.round(Date.now() * 1e-3)
      )
      // content = content.replace(/"FIRESTORE_ID"/g, `"${randomDocId()}"`); // FIXME: Applies the same doc ID to all instances

      content = content
        .split('"FIRESTORE_ID"')
        .map((textPart, index, arr) =>
          index < arr.length - 1 ? `${textPart}"${randomDocId()}"` : textPart
        )
        .join()

      return {
        filePath,
        filePathParts,
        fileName,
        fileExtension,
        content,
        nodeType: "json",
      }
    }
  )
  const allNodes = [...markdownNodes, ...jsonNodes]
  // console.log(JSON.stringify(allNodes, null, 2));

  const contentMap = extractContent(allNodes)
  const content = Object.entries(contentMap)
    .map(([title, sections]) => ({
      title: capitalizeFirstLetter(title.split("-").join(" ")),
      sections: Object.entries(sections)
        .map(
          ([
            _,
            {
              object,
              endpoints,
              apiPath,
              sections: customInnerSections,
              title: sectionTitle,
              content: sectionContent,
              aside,
            },
          ]) => ({
            title: formatTitle(sectionTitle),
            object,
            aside,
            endpoints:
              endpoints &&
              Object.entries(endpoints)
                .map(([slug, endpointContent]) => ({
                  slug,
                  ...endpointContent,
                }))
                .sort((a, b) => {
                  const [aIndex] = a.slug.split("_")
                  const [bIndex] = b.slug.split("_")
                  return aIndex - bIndex
                })
                .map(({ slug, ...endpointContent }) => ({
                  slug: slug.split("_")[1],
                  ...endpointContent,
                })),
            apiPath,
            content: sectionContent,
            sections:
              customInnerSections &&
              Object.entries(customInnerSections).map(
                ([innerSectionTitle, innerSection]) => ({
                  title: formatTitle(innerSectionTitle),
                  content: innerSection.content,
                  aside: innerSection.aside,
                })
              ),
          })
        )
        .sort((a, b) => {
          if (a.title < b.title) {
            return -1
          }
          if (a.title > b.title) {
            return 1
          }
          return 0
        }),
    }))
    .sort((a, b) => {
      const [groupNumberA] = a.title.split("_")
      const [groupNumberB] = b.title.split("_")
      return groupNumberA - groupNumberB
    })
    .map(({ title, ...groupData }) => ({
      title: formatTitle(title),
      ...groupData,
    }))
    .map(group => {
      const groupSlug = slugify(group.title)
      return {
        ...group,
        slug: groupSlug,
        sections: group.sections.map(section => {
          const sectionSlug = `${groupSlug}_${slugify(section.title)}`
          return {
            ...section,
            slug: sectionSlug,
            ...(section.sections && {
              sections: section.sections.map(innerSection => {
                const innerSectionSlug = `${sectionSlug}_${slugify(
                  innerSection.title
                )}`
                return {
                  ...innerSection,
                  slug: innerSectionSlug,
                }
              }),
            }),
            ...(section.object && {
              object: {
                ...section.object,
                slug: `${sectionSlug}_${slugify(section.object.title)}`,
              },
            }),
            ...(section.endpoints && {
              endpoints: section.endpoints.map(endpoint => {
                const endpointSlug = `${sectionSlug}_${slugify(endpoint.title)}`
                return {
                  ...endpoint,
                  slug: endpointSlug,
                }
              }),
            }),
          }
        }),
      }
    })

  // console.log("Transformed content:", JSON.stringify(content, null, 2));
  return content
}

export { transformContent }

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
require("typeface-inter")
require("typeface-space-mono")
require("@fontsource/space-grotesk/300.css")
require("@fontsource/space-grotesk/400.css")
require("@fontsource/space-grotesk/500.css")
require("@fontsource/space-grotesk/600.css")
require("@fontsource/space-grotesk/700.css")
require("./src/components/layout.css")

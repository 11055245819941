/* eslint-disable import/no-named-as-default */
/* eslint-disable import/named */

import * as React from "react"
import styled from "styled-components"

// eslint-disable-next-line import/no-named-as-default-member
// import Layout from "../components/layout";
import Seo from "../components/seo"

const PageContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const NotFoundPage = () => (
  <PageContainer>
    <Seo title="404: Not found" />
    <h1>404: Not Found</h1>
    <p>We couldn't find a page here...</p>
    <p>(Please go back)</p>
  </PageContainer>
)

export default NotFoundPage
